:root {
  --white-color: #fff;
  --black-color: #000;
  --charcoal-color: #241C24;
  --red-color: #BC1D2B;
  --orange-color: #F7931D;
  --lightgrey-color: #f9f9f9;
  --box-shadow: 0 1px 3px #0000001a,0 2px 2px #0000000f,0 0 2px #00000012;
  --font-family-poppins: 'Poppins', sans-serif;
  --body-regular-font: calc(12px + 1vmin);
  --mobile-medium: calc(18px + 1vmin);
  --mobile-small: calc(16px + 1vmin);
  --mobile-x-small: calc(14px + 1vmin);
  --header-large: calc(30px + 1vmin);
  --header-medium: calc(20px + 1vmin);
  --header-small: calc(12px + 1vmin);
  --body-x-small: calc(10px + 1vmin);


  --body-font: calc(12px + 1vmin);
  --header-font-h3-mobile: calc(18px + 1vmin);
  --header-font-h4-mobile: calc(16px + 1vmin);
  --header-font-h5-mobile: calc(14px + 1vmin);
  --header-font-h3: calc(30px + 1vmin);
  --header-font-medium: calc(14px + 1vmin);
  --header-font-h5: calc(12px + 1vmin);
}

.App {
  background: var(--white-color);
}
.App-logo {
  width: 160px;
}
.App .ui.icon.message {
  background: var(--lightgrey-color);
}
.App * {
  font-size: var(--body-regular-font);
  font-family: var(--font-family-poppins);
  font-weight: 400;
}
.App.page .hero {
  margin-bottom: 1rem;
}
.App .notice {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.ui.header.large {
  font-family: var(--font-family-poppins);
}
.ui.grid>.red.row {
  background: var(--red-color) !important;
}
.App .ui.message .content .header,
.App .description {
  font-size: var(--body-regular-font);
  line-height: 1;
}
.App .description {
  margin-bottom: 1.5rem;
}
.App.socials .section-item .medium.header {
  font-size: var(--header-large);
  margin-bottom: 0;
}
.App.classes .class-description {
  font-size: var(--body-regular-font);
  font-family: var(--font-family-poppins);
}
.App.page p {
  font-family: var(--font-family-poppins);
}
.App.classes .list .item .content {
  line-height: 1.3;;
}
.App .App-header .primary.menu .menu a {
  font-size: var(--body-regular-font);
}
.main .section-item .medium.header {
  margin-bottom: 1.5rem;
}
.main .section-item.classes .medium.header {
  margin-bottom: 1rem;
  font-family: var(--font-family-poppins);
}
.main .section-item.classes .ui.green.button {
  font-family: var(--font-family-poppins);
  font-weight: 400;
}
.main .ui.header.scheduled-classes {
  margin-bottom: 1.5rem;
}
.App .ui.message .header:not(.ui) {
  font-size: var(--header-large);
  font-family: var(--font-family-poppins);
}
.App.page .ui.warning.message .header {
  line-height: 1.1;
}
.App .main .ui.grid .column .classes {
  margin: 3% 0;
}
.App.page .main .section {
  padding: 0 0 5%;
}
.App.page .ui.breadcrumb .section,
.App.page .ui.breadcrumb,
.App .ui.breadcrumb {
  color: var(--white-color);
}
.App .main .ui.grid .column .socials {
  padding: 0 2% 0;
  margin: 0;
}
.App .main .ui.grid .column .socials.active {
  position: relative;
}
.App .main .ui.grid .column .socials .header {
  margin-bottom: 0;
  font-family: var(--font-family-poppins);
}
.App.page .App-breadcrumb {
  margin-bottom: 0;
}
.main .App-breadcrumb .ui.breadcrumb .divider {
  color: var(--white-color);
}
.App .App-breadcrumb .ui.breadcrumb a,
.App .App-breadcrumb .ui.breadcrumb .active {
  font-size: 1rem;
}
.App-header {
  position: relative;
  z-index: 1;
}
.App-header .primary.menu {
  border-radius: 0;
  box-shadow: var(--box-shadow);
}
.App-header h1.header {
  display: none;
}
.App-header .main-header {
  font-size: var(--header-medium);
}
.main .class-items .sub.header {
  margin-bottom: 1rem;
}
.main .homepage-selections > a {
  margin-bottom: 1rem;
  display: block;
}
.main .ui.class-grid>.row>.column {
  margin-bottom: 2rem;
}
.App-breadcrumb {
  margin-bottom: 2rem;
  background: var(--red-color);
}
.App-breadcrumb .ui.breadcrumb a {
  color: var(--white-color);
}
.App-breadcrumb .header.upcoming-socials {
  color: var(--white-color);
  padding: 1rem 0;
  font-size: 1rem !important;
  font-weight: 400;
}
.App.page .ui.grid>.row>.col {
  margin-bottom: 0.5rem;
}

.App.page .options .ui.header.intro-header {
  font-size: var(--header-font-medium);
  font-family: var(--font-family-poppins);
  margin-top: 2rem;
}
.App.page .offering .medium.header {
  font-size: var(--header-large);
}
.App.page .offering .sub.header {
  font-size: var(--header-font-h5);
  margin: 1rem 0;
}
.App.page .social-accordion .title {
  font-weight: 700;
  font-size: var(--header-font-h4-mobile);
}
.App.page .bottom-margin {
  margin-bottom: 1rem;
}

/* Remove drop shadow and borders from Menu */
body .ui.menu {
  box-shadow: none;
  border: none;
  margin-bottom: 0;
  margin-top: 0;
}
body .ui.menu a.item:hover {
  background: none;
}

/* Remove box shadow from Menu Items */
body .ui.menu .item {
  box-shadow: none;
}

/* Optional: If you want to remove the underline effect on active Menu Items */
body .ui.menu .active.item {
  border-bottom: none;
}

/* Optional: If you want to remove the border between Menu Items */
body .ui.menu .item:before {
  content: none;
}

/* Optional: If you want to remove the border between Menu Items on hover */
body .ui.menu .item:hover:before {
  content: none;
}

.footer {
  padding: 0.5rem 0.8rem;
}
.footer .ui.grid {
  margin-bottom: 1.5rem;
}
.footer .col span {
  margin-right: 25px;
}
.footer .ui.horizontal.list>.item {
  font-size: var(--body-regular-font); 
}
.main .App-breadcrumb .ui.breadcrumb a:hover {
  color: var(--white-color);
}
.main .App-breadcrumb .ui.breadcrumb .active.section {
  font-weight: normal;
  color: var(--white-color);
}

.App.page .main .ui.icon.message svg {
  margin-right: 1.8rem;
}

.ui.grid {
  margin-top: 0 !important;
}
.App-header .mobile.menu {
  border-radius: 0;
}
.App .ui.fluid.button.mobile {
  display: none;
}
.App .ui.grid>.row>[class*="sixteen wide mobile"].column .location {
  margin-bottom: 0;
}
.App.page .ui.container.home .ui.stackable.grid:not(.vertically)>.section.desktop.icon {
  display: none;
}
.App.page .ui.container.home .ui.stackable.grid:not(.vertically)>.section.desktop.img {
  display: block;
}
p.p1 {
  margin-top: 1rem;
}
.App .class-registration .class-filter {
  margin-bottom: 1.5rem;
}
.App .ui.grid>.row>[class*="eight wide computer"].secondary-menu .ui.list>a.item {
  color: var(--black-color);
  font-size: var(--body-regular-font);
}
.App .ui.grid>.row>[class*="sixteen wide computer"].credit p {
  font-size: var(--body-x-small);
}


/* Ensure the bottom icons section sticks to the bottom of the screen */
.bottom-icons {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white; /* Optional: background color for the footer */
  padding: 10px 0; /* Optional: padding for spacing */
  box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.1); /* Optional: shadow for better visibility */
}

/* Styling for the icon links */
.icon-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.icon {
  font-size: 2em;
  color: #333;
}

.icon-link:hover .icon {
  color: #007bff;
}

.App .social-dates {
  padding-bottom: 10vh;
}

.App .contact {
  padding-bottom: 10vh;
}

.App .conduct {
  padding-bottom: 10vh;
}

.App .family {
  padding-bottom: 10vh;
}

.App .additional-pages {
  padding-bottom: 10vh;
}

.App .class-dates {
  padding-bottom: 15vh;
}


@media only screen and (max-width: 767px){
  .page .hero.home {
    height: 40vw;
  }
  .App-header {
    /* margin-bottom: 1rem; */
    margin-bottom: 0;
  }
  .App-header .ui.container {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .App-header .ui.small.menu {
    position: fixed;
    z-index: 1;
  }
  .App.mobile {
    padding-top: 80px;
  }
  .App-header .ui.small.red {
    background: var(--red-color);
  }
  .App-header .ui.menu.red .item {
    color: var(--white-color);
    text-align: center;
  }
  .App-header .mobile.menu {
    margin-top: 3px;
    position: relative;
  }
  .App.page .offering .medium.header {
    font-size: var(--mobile-medium);
  }
  .App.page .offering .sub.header {
    font-size: var(--mobile-x-small);
  }
  .App.page .options .ui.header.intro-header {
    font-size: var(--header-font-medium);
    padding-left: 0;
  }
  .App-header .ui.stackable.menu .item {
    font-size: var(--body-regular-font);
    border-radius: 0;
  }
  .App .main .ui.grid .column .socials {
    margin: 3% 0;
  }
  .App .main .ui.grid .column .classes {
    margin: 8% 0;
  }
  .footer .ui.horizontal.list>.item {
    font-size: var(--body-x-small);
  }
  .footer .ui.stackable.grid>.row>.col {
    padding-bottom: 0 !important;
  }
  .footer .ui.grid>.row>.col {
    margin-bottom: 1rem;
  }
  .footer .col span {
    display: block;
    margin-right: 0;
    margin-bottom: 10px;
  }
  .sticky-button {
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    padding: 20px;
    background-color: var(--white-color);
    box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1); /* Optional: Add box shadow for better visibility */
  }
  .reverse-mobile {
    flex-direction: column-reverse !important;
  }
  .App .ui.fluid.button.mobile {
    display: block;
    margin-top: 1rem;
    background: var(--red-color);
  }
  .App .ui.grid>.row>[class*="sixteen wide mobile"].last {
    margin-top: 2rem;
  }
  .App.page .ui.container.home .ui.stackable.grid:not(.vertically)>.section.mobile.icon {
    display: block;
  }
  .App.page .ui.container.home .ui.stackable.grid:not(.vertically)>.section.mobile.img {
    display: none;
  }
  .ui.grid>.row>[class*="sixteen wide mobile"].google-review {
    margin-top: 2rem;
  }
  .ui[class*="right floated"].list {
    float: left;
  }
  .App .ui.grid>.sub-menu>[class*="sixteen wide mobile"].social-media {
    border-bottom: 1px solid rgba(34,36,38,.15);
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }
  .App .ui.ui.ui.container.home {
    margin-bottom: 3rem;
  }
  .App .social-dates {
    padding-bottom: 15vh;
  }
  .App .additional-pages {
    padding-bottom: 10vh;
  }
  .App .class-dates {
    padding-bottom: 15vh;
  }
  .App .contact {
    padding-bottom: 15vh;
  }
  .App .family {
    padding-bottom: 10vh;
  }
  .App .conduct {
    padding-bottom: 15vh;
  }
  .ui[class*="bottom fixed"].menu {
    border-top: 1px solid #DEDEDF;
  }
}

